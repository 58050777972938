













import {defineComponent} from '@vue/composition-api';
import AssetsImg from "@/common/ts/components/AssetsImg.vue";

interface ViewJsonIF {
  contactTel: string;
}

export default defineComponent({
  name: 'FreePhoneArea',
  components: {AssetsImg},
  setup(){
    const json = JSON.parse(document.documentElement.dataset.viewJson || '') as ViewJsonIF
    const contactTel = json.contactTel
    return {
      contactTel
      }
    }
})
