import $ from 'jquery';
import Vue from 'vue';
import VueCompositionApi from '@vue/composition-api';
import Top from "@/pc/ts/components/pages/top/Top.vue";
import VueScrollTo from 'vue-scrollto'

Vue.use(VueCompositionApi);

//「トップへ」ボタンスクロール用
Vue.use(VueScrollTo, {
  offset: -1000,
  duration: 800,
})

Vue.config.errorHandler = e => {
  console.error(e);
};

$(() => {
  new Vue({ //ヘッダー
    render: h =>h(Top)
  }).$mount('#js-top')
});
