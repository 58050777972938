




import {defineComponent} from '@vue/composition-api'

type Props = {
  src: string;
  alt: string;
}

export default defineComponent({
  name: "AssetsImg",
  props:{
    src: {type: String, required: true},
    alt: {type: String, required: false, default: ''},
  },
  setup(props: Props){
    return {
      props,
    }
  }
})
