




































import {defineComponent} from '@vue/composition-api';
import AssetsImg from "@/common/ts/components/AssetsImg.vue";
import {addComma, nowYearMonthForView} from "@/common/ts/ViewCommons"

interface ViewJsonIF {
  foundingYears: string;
  counselingCount: number;
  facilityCount: number;
  nowYearMonth: string;
  contactTel: string;
}

interface MapItemsIF {
  parentClassName: string;
	area: Array<{
		className: string;
		link: string;
		name: string;
	}>;
}

export default defineComponent({
	name: 'TopMapSearch',
	components: {AssetsImg},
	setup(){
		const json = JSON.parse(document.documentElement.dataset.viewJson || '') as ViewJsonIF
		const foundingYears = json.foundingYears
		const counselingCount = addComma(json.counselingCount)
		const facilityCount = addComma(json.facilityCount)
		const contactTel = json.contactTel
		const nowYearMonth = nowYearMonthForView()
		const mapItems: MapItemsIF[] = [
			{
				parentClassName: 'area01',
				area: [
					{
						className: "mapHokkaido",
						link: "/list/1/",
						name: "北海道"
					}
				]
			},
			{
				parentClassName: 'area02',
				area: [
					{
						className: "mapAomori",
						link: "/list/2/",
						name: "青森"
					},
					{
						className: "mapIwate",
						link: "/list/3/",
						name: "岩手"
					},
					{
						className: "mapMiyagi",
						link: "/list/4/",
						name: "宮城"
					},
					{
						className: "mapAkita",
						link: "/list/5/",
						name: "秋田"
					},
					{
						className: "mapYamagata",
						link: "/list/6/",
						name: "山形"
					},
					{
						className: "mapFukushima",
						link: "/list/7/",
						name: "福島"
					}
				]
			},
			{
				parentClassName: 'area03',
				area: [
					{
						className: "mapTokyo",
						link: "/list/13/",
						name: "東京"
					},
					{
						className: "mapKanagawa",
						link: "/list/14/",
						name: "神奈川"
					},
					{
						className: "mapSaitama",
						link: "/list/11/",
						name: "埼玉"
					},
					{
						className: "mapChiba",
						link: "/list/12/",
						name: "千葉"
					},
					{
						className: "mapIbaraki",
						link: "/list/8/",
						name: "茨城"
					},
					{
						className: "mapTochigi",
						link: "/list/9/",
						name: "栃木"
					},
					{
						className: "mapGunma",
						link: "/list/10/",
						name: "群馬"
					}
				]
			},
			{
				parentClassName: 'area04',
				area: [
					{
						className: "mapNiigata",
						link: "/list/15/",
						name: "新潟"
					},
					{
						className: "mapToyama",
						link: "/list/16/",
						name: "富山"
					},
					{
						className: "mapIshikawa",
						link: "/list/17/",
						name: "石川"
					},
					{
						className: "mapFukui",
						link: "/list/18/",
						name: "福井"
					},
					{
						className: "mapYamanashi",
						link: "/list/19/",
						name: "山梨"
					},
					{
						className: "mapNagano",
						link: "/list/20/",
						name: "長野"
					}
				]
			},
			{
				parentClassName: 'area05',
				area: [
					{
						className: "mapAichi",
						link: "/list/23/",
						name: "愛知"
					},
					{
						className: "mapShizuoka",
						link: "/list/22/",
						name: "静岡"
					},
					{
						className: "mapMie",
						link: "/list/24/",
						name: "三重"
					},
					{
						className: "mapGifu",
						link: "/list/21/",
						name: "岐阜"
					}
				]
			},
			{
				parentClassName: 'area06',
				area: [
					{
						className: "mapOsaka",
						link: "/list/27/",
						name: "大阪"
					},
					{
						className: "mapHyogo",
						link: "/list/28/",
						name: "兵庫"
					},
					{
						className: "mapKyoto",
						link: "/list/26/",
						name: "京都"
					},
					{
						className: "mapShiga",
						link: "/list/25/",
						name: "滋賀"
					},
					{
						className: "mapNara",
						link: "/list/29/",
						name: "奈良"
					},
					{
						className: "mapWakayama",
						link: "/list/30/",
						name: "和歌山"
					}
				]
			},
			{
				parentClassName: 'area07',
				area: [
					{
						className: "mapOkayama",
						link: "/list/33/",
						name: "岡山"
					},
					{
						className: "mapHiroshima",
						link: "/list/34/",
						name: "広島"
					},
					{
						className: "mapTottori",
						link: "/list/31/",
						name: "鳥取"
					},
					{
						className: "mapShimane",
						link: "/list/32/",
						name: "島根"
					},
					{
						className: "mapYamaguchi",
						link: "/list/35/",
						name: "山口"
					}
				]
			},
			{
				parentClassName: 'area08',
				area: [
					{
						className: "mapEhime",
						link: "/list/38/",
						name: "愛媛"
					},
					{
						className: "mapKagawa",
						link: "/list/37/",
						name: "香川"
					},
					{
						className: "mapKochi",
						link: "/list/39/",
						name: "高知"
					},
					{
						className: "mapTokushima",
						link: "/list/36/",
						name: "徳島"
					}
				]
			},
			{
				parentClassName: 'area09',
				area: [
					{
						className: "mapFukuoka",
						link: "/list/40/",
						name: "福岡"
					},
					{
						className: "mapSaga",
						link: "/list/41/",
						name: "佐賀"
					},
					{
						className: "mapNagasaki",
						link: "/list/42/",
						name: "長崎"
					},
					{
						className: "mapKumamoto",
						link: "/list/43/",
						name: "熊本"
					},
					{
						className: "mapOita",
						link: "/list/44/",
						name: "大分"
					},
					{
						className: "mapMiyazaki",
						link: "/list/45/",
						name: "宮崎"
					},
					{
						className: "mapKagoshima",
						link: "/list/46/",
						name: "鹿児島"
					},
				]
			},
			{
				parentClassName: 'area10',
				area: [
					{
						className: "mapOkinawa",
						link: "/list/47/",
						name: "沖縄"
					}
				]
			}
		]
		return {
			foundingYears,
			counselingCount,
			facilityCount,
			nowYearMonth,
			contactTel,
			mapItems
		}
	}
})
