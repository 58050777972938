













import {defineComponent} from '@vue/composition-api'
import AssetsImg from "@/common/ts/components/AssetsImg.vue";

export default defineComponent({
  name: "GlovanNav",
  components: {AssetsImg},
  setup() {
  }
})
