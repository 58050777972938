








import {defineComponent} from "@vue/composition-api";
import TopNewsGuideLinkList from "@/pc/ts/components/pages/top/TopNewsGuideLinkList.vue";

export default defineComponent({
  name: "TopNewsGuideLinkContents",
  components: {TopNewsGuideLinkList},
  setup() {
  }
})
