










import {defineComponent} from '@vue/composition-api'

interface InformationIF {
	postDay: string;
	urlWord: string;
	headline: string;
}

interface ViewJsonIF {
	information: InformationIF[];
}

export default defineComponent({
  name: "TopInfoArea",
  setup() {
		const json = JSON.parse(document.documentElement.dataset.viewJson || '') as ViewJsonIF
		const informationItems = json.information
		const infoHtml = informationItems.map(i => {
				if(i.urlWord.length > 0){
					return `
					<article>
							<span>${i.postDay}</span>
							<a href="/information/${i.urlWord}/">${i.headline}</a>
					</article>`
					}else {
					return `
					<article>
							<span>${i.postDay}</span>
							<span>${i.headline}</span>
					</article>`
				}
		}).join("")
		return {
			informationItems,
      infoHtml
		}
  }
})
