







import Vue from 'vue';
import { defineComponent, ref, onMounted } from "@vue/composition-api";

export default defineComponent({
  name: "TopIeUnSupport",
  setup(){
    const ieUnSupport = ref(false);
    onMounted(() => {
      Vue.nextTick(() => {
        const userAgent = window.navigator.userAgent.toUpperCase();
        if (userAgent.includes("MSIE") || userAgent.includes("TRIDENT")) {
          ieUnSupport.value = true;
        }
      });
    });
    const popUpWin = () => {
      window.open(
        'https://www.webcrew.co.jp/browser/',
        '_blank',
        'width=500,height=600,menubar=no,toolbar=no,scrollbars=yes'
      )
    }
    return {
      ieUnSupport,
      popUpWin
    }
  }
})
