const date = new Date()

/**
 * 現在日付（表示用）
 */
export const nowYearMonthForView = (): string =>
  (date.getMonth() + 1) + "月" + ("00" + date.getDate()).slice(-2) + "日";
/**
 * 3桁カンマフォーマット(施設掲載数・相談実績)
 * @param value
 */
export const addComma = (value: number): string => value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

/**
 * 文字列: #GCSDOMAIN# のドメイン置換（表示用）
 * @param imageSrc
 * @param gcsDomain
 */
export const replaceImageSrcForView = (imageSrc: string, gcsDomain: string): string => imageSrc.replace("#GCSDOMAIN#", gcsDomain)

/**
 * テーマ別特集コンテンツ画像（表示用）
 * @param imageName
 * @param gcsDomain
 */
export const themeContentImageForView = (imageName: string, gcsDomain: string): string => gcsDomain + imageName