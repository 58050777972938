






import Vue from 'vue';
import { defineComponent } from '@vue/composition-api';
import AssetsImg from "@/common/ts/components/AssetsImg.vue";

Vue.directive('scroll', {
  inserted: function (el, binding) {
    const f = function (evt: any) {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', f)
      }
    }
    window.addEventListener('scroll', f)
  }
})

export default defineComponent({
    name: 'BackTop',
    components: {AssetsImg},
    setup(){
        const handleScroll = (evt: any, el: any) => {
            if (window.scrollY > 50) {
                el.setAttribute(
                    'style',
                    'opacity: 1; transform: translate(0);'
                )
            }
            else {
                el.setAttribute(
                    'style',
                    'opacity: 0; transition: all .8s;'
                )
            }
        }
        return {
            handleScroll
        }
    }
})
