







import {defineComponent} from '@vue/composition-api'

interface FacilityInquiriesIF {
	categoryId: string;
	displayName: string;
	facilityId: number;
	groupingId: string;
	id: number;
	insertedOn: string;
	lastWording: string;
}

interface GroupedInquiryIF {
	insertedOn: string;
	facilityInquiryId: number;
	facilityInquiries: FacilityInquiriesIF[];
}

interface ViewJsonIF {
	groupedFacilityInquiry: GroupedInquiryIF[];
}

export default defineComponent({
  name: "TopResentArrivalArea",
  setup() {
		const json = JSON.parse(document.documentElement.dataset.viewJson || '') as ViewJsonIF
		const groupedFacilityInquiryArray = json.groupedFacilityInquiry

		//HTMLに変換する処理
		const arrivalHtml = groupedFacilityInquiryArray.map(g =>{
			//施設名一覧を出力する処理（らくらく資料請求含む）
			const facilityInquiryLinks = g.facilityInquiries.map(f =>{
				if(f.facilityId > 0){
				return `
				<li><a href="/facility/${f.facilityId}/" target="_blank">${f.displayName}</a></li>
				`
				} else {
				return `
				<li><a href="/step/request/step_init.html">${f.displayName}</a></li>
				`
				}
			}).join("")

			//CV毎の文言を取り出す
			const facilityInquiryWording = g.facilityInquiries[0].lastWording

			//完成
			return `
			<li><span>${g.insertedOn}</span><ul>${facilityInquiryLinks}</ul>${facilityInquiryWording}</li>
			`
		}).join("")

		return {
			groupedFacilityInquiryArray,
			arrivalHtml
		}
  }
})
