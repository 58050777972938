











import {defineComponent} from '@vue/composition-api';
import AssetsImg from "@/common/ts/components/AssetsImg.vue";

interface ViewJsonIF {
  medicalCheckupPdf: string;
  gcsDomain: string;
}

export default defineComponent({
    name: 'TopBannerArea1',
    components: {AssetsImg},
    setup(){
        const json = JSON.parse(document.documentElement.dataset.viewJson || '') as ViewJsonIF
        const medicalCheckupPdf = json.medicalCheckupPdf
        const gcsDomain = json.gcsDomain
        return {
          medicalCheckupPdf,
          gcsDomain
        }
    }
})
