


































import {defineComponent} from '@vue/composition-api';
import TopIeUnSupport from "@/pc/ts/components/pages/top/TopIeUnSupport.vue"; //IEサポート外表示
import Header from "@/pc/ts/components/layouts/Header.vue"; //ヘッダー
import GlobalNav from "@/pc/ts/components/layouts/GlobalNav.vue"; //グロナビ
import TopMapSearch from "@/pc/ts/components/pages/top/TopMapSearch.vue"; //地図検索
import TopContentsBannerArea from "@/pc/ts/components/pages/top/TopContentsBannerArea.vue"; //診断コンテンツバナー
import TopInfoArea from "@/pc/ts/components/pages/top/TopInfoArea.vue"; //お知らせ
import TopResentArrivalArea from "@/pc/ts/components/pages/top/TopResentArrivalArea.vue"; //現在のお問い合わせ状況
import TopFacilityFutureArea from "@/pc/ts/components/pages/top/TopFacilityFutureArea.vue"; //施設の特徴から探す
import TopFlowArea from "@/pc/ts/components/pages/top/TopFlowArea.vue"; //悩んだら相談！シニアのあんしん相談室のご紹介
import TopRequestStepArea from "@/pc/ts/components/pages/top/TopRequestStepArea.vue"; //資料請求・電話番号
import TopGuideLinkContents from "@/pc/ts/components/pages/top/TopGuideLinkContents.vue"; //介護ガイド
import TopNewsGuideLinkContents from "@/pc/ts/components/pages/top/TopNewsGuideLinkContents.vue"; //介護ニュース
import TopFaqArea from "@/pc/ts/components/pages/top/TopFaqArea.vue"; //よくある質問
import TopVoiceArea from "@/pc/ts/components/pages/top/TopVoiceArea.vue"; //よくある質問
import TopCompaniesArea from "@/pc/ts/components/pages/top/TopCompaniesArea.vue"; //参加している運営会社
import TopBannerArea1 from "@/pc/ts/components/pages/top/TopBannerArea1.vue"; //バナーエリア1
import TopServiceAndMediaArea from "@/pc/ts/components/pages/top/TopServiceAndMediaArea.vue"; //「シニアのあんしん相談室」はシニア向けサービスを中心とした相談窓口です＆「シニアのあんしん相談室」がメディアで紹介されました
import TopOutSideArea from "@/pc/ts/components/pages/top/TopOutSideArea.vue"; //外部サイト遷移エリア
import TopBannerArea2 from "@/pc/ts/components/pages/top/TopBannerArea2.vue"; //バナーエリア2
import FreePhoneArea from "@/pc/ts/components/modules/FreePhoneArea.vue"; //フリーダイヤルエリア
import BackTop from "@/pc/ts/components/ui/BackTop.vue"; //「トップへ」ボタンス
import BottomContents from "@/pc/ts/components/layouts/BottomContents.vue"; //ページ内・外部リンク
import Footer from "@/pc/ts/components/layouts/Footer.vue"; //フッター

export default defineComponent({
	name: 'Top',
	components: {
		TopIeUnSupport,
		Header,
		GlobalNav,
		TopMapSearch,
		TopContentsBannerArea,
		TopInfoArea,
		TopResentArrivalArea,
		TopFacilityFutureArea,
		TopFlowArea,
		TopRequestStepArea,
		TopGuideLinkContents,
		TopNewsGuideLinkContents,
		TopFaqArea,
		TopVoiceArea,
		TopCompaniesArea,
		TopBannerArea1,
		TopServiceAndMediaArea,
		TopOutSideArea,
		TopBannerArea2,
		FreePhoneArea,
		BackTop,
		BottomContents,
		Footer
	},
	setup(){
	}
})
