















import {defineComponent} from '@vue/composition-api'
import AssetsImg from "@/common/ts/components/AssetsImg.vue";
import {replaceImageSrcForView} from "@/common/ts/ViewCommons";

interface NewsIF{
  urlWord: string;
  headlineTop: string;
  categoryName: string;
  categoryValue: string;
  postDay: string;
  leadImage: string;
  leadImageAlt: string;
  isPickup: boolean;
  isPickupClass? : string;
}

interface ViewJsonIF {
  news: NewsIF[];
  gcsDomain: string;
}

export default defineComponent({
  name: "TopNewsGuideLinkList",
  components: {AssetsImg},
  setup(){
    const json = JSON.parse(document.documentElement.dataset.viewJson || '') as ViewJsonIF
    const newsSorted = json.news.sort((before, after) => {
    const beforePostDay = parseInt(before.postDay.replace(/\./g, ""))
    const afterPostDay = parseInt(after.postDay.replace(/\./g, ""))
    return beforePostDay < afterPostDay ? 1 : -1  // 降順ソート
    }).sort((before, after) => {
      const isPickUpBefore = Number(before.isPickup)
      const isPickUpAfter = Number(after.isPickup)
      // ピックアップ対象を優先して上位にする
      if(isPickUpBefore < isPickUpAfter) {
        return 1;
      }
      else if(isPickUpBefore == isPickUpAfter){
        return 0;
      }
      else {
        return -1
      }
    }).slice(0, 8)
    const gcsDomain = json.gcsDomain
    const newsComplicated = newsSorted.map(n => {
      n.isPickupClass = n.isPickup ? 'pickup' : ''
      n.leadImage = replaceImageSrcForView(n.leadImage, gcsDomain)
      return n
    })
    return {
      newsComplicated
    }
  }
})
