












import {defineComponent} from "@vue/composition-api";
import ThumbnailLinkList, {ThumbnailLinkItemIF} from "@/pc/ts/components/modules/ThumbnailLinkList.vue"
import {replaceImageSrcForView} from "@/common/ts/ViewCommons"

interface ViewJsonIF {
  guideCategories: LinkContentIF[];
  guideSelectContents: LinkContentIF[];
  gcsDomain: string;
}

interface LinkContentIF {
  title: string;
  description: string;
  urlWord: string;
  imgSrc: string;
  imgAlt: string;
}

export default defineComponent( {
  name: "TopGuideLinkContents",
  components: {ThumbnailLinkList},
  setup(){
    const json = JSON.parse(document.documentElement.dataset.viewJson || '') as ViewJsonIF
    const gcsDomain = json.gcsDomain
    const guideThumbnailLinks: ThumbnailLinkItemIF[] = json.guideCategories.map(c => {
      return {
        link: c.urlWord,
        imgSrc: replaceImageSrcForView(c.imgSrc, gcsDomain),
        imgAlt: c.imgAlt,
        name: c.title,
        text: c.description
      }
    })
    const guideSelectThumbnailLinks: ThumbnailLinkItemIF[] = json.guideSelectContents.map(c => {
      return {
        link: c.urlWord,
        imgSrc: replaceImageSrcForView(c.imgSrc, gcsDomain),
        imgAlt: c.imgAlt,
        name: c.title,
        text: c.description
      }
    })


    return {
      guideThumbnailLinks,
      guideSelectThumbnailLinks
    }

  }
})
