


























import {defineComponent,reactive} from '@vue/composition-api'
import AssetsImg from "@/common/ts/components/AssetsImg.vue";

export interface ThumbnailLinkItemIF {
  link: string;
  imgSrc: string;
  alt: string;
  name: string;
  text: string;
}

type Props = {
  linkItems: ThumbnailLinkItemIF[];
}

export default defineComponent({
  name: "TopThumbnailLinkList",
  components: {AssetsImg},
  props: {
    linkItems: Array as () => ThumbnailLinkItemIF[]
  },
  setup(props: Props) {
    const state = reactive<{ isOpened: boolean }>({
      isOpened: false
    });
    const accordionToggle = (): void => {
      state.isOpened = !state.isOpened;
    }
    return {
      props,
      state,
      accordionToggle,
    }
  }
})
