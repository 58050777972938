








import {defineComponent} from '@vue/composition-api';
import AssetsImg from "@/common/ts/components/AssetsImg.vue";
import FooterIsoArea from "@/pc/ts/components/layouts/FooterIsoArea.vue";
import FooterLink from "@/pc/ts/components/layouts/FooterLink.vue";

export default defineComponent({
  name: 'Footer',
  components: {AssetsImg, FooterIsoArea, FooterLink},
  setup(){
	}
})
