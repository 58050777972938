










import {defineComponent} from '@vue/composition-api'
import TopThumbnailLinkList, {ThumbnailLinkItemIF} from "@/pc/ts/components/pages/top/TopThumbnailLinkList.vue";

type Props = {
  headline: string;
  lead: string;
  contentsListItems: ThumbnailLinkItemIF[];
  ulClass?: string;
  href?: string;
  hrefText?: string;
}

export default defineComponent({
  name: "TopFacilityContentsSection",
  components: {TopThumbnailLinkList},
  props: {
    headline: {
      type: String,
      require: true
    },
    lead: {
      type: String,
      require: true
    },
    contentsListItems: {
      type: Array as () => ThumbnailLinkItemIF[],
    },
    ulClass: {
      type: String,
      require: false
    },
    href: {
      type: String,
      require: false
    },
    hrefText: {
      type: String,
      require: false
    },
  },
  setup(props: Props) {
    const isSectionShow = props.contentsListItems.length != 0
    const isHrefShow = props.href?.length != 0 && props.hrefText?.length != 0
    return {
      props,
      isSectionShow,
      isHrefShow
    }
  }
})
