












import {defineComponent} from '@vue/composition-api'
import AssetsImg from "@/common/ts/components/AssetsImg.vue";

export interface ThumbnailLinkItemIF {
  link: string;
  imgSrc: string;
  imgAlt?: string;
  name: string;
  text: string;
}

type Props = {
  linkItems: ThumbnailLinkItemIF[];
  ulClass?: string;
}

export default defineComponent({
  name: "ThumbnailLinkList",
  components: {AssetsImg},
  props: {
    linkItems: Array as () => ThumbnailLinkItemIF[],
    ulClass: {
      type: String,
      require: false
    }
  },
  setup(props: Props) {
    return {
      props
    }
  }
})
