






























import { defineComponent } from '@vue/composition-api';
import VueScriptComponent from 'vue-script-component';
import AssetsImg from '@/common/ts/components/AssetsImg.vue';

interface ViewJsonIF {
  contactTel: string;
}

export default defineComponent({
  name: 'Header',
  components: { AssetsImg, VueScriptComponent },
  setup() {
    const json = JSON.parse(document.documentElement.dataset.viewJson || '') as ViewJsonIF;
    const contactTel = json.contactTel;
    const currentPath = location.pathname;
    return {
      contactTel,
      currentPath,
    };
  },
});
