























import {defineComponent} from '@vue/composition-api'
import FaqAccordion from '@/pc/ts/components/modules/FaqAccordion.vue'

interface FaqIF {
	question: string;
	answerSummary: string;
	answer: string;
	category: {name: string};
}

interface ViewJsonIF {
  popularFaqs: FaqIF[];
}

export default defineComponent({
	name: "TopFaqArea",
  components: {FaqAccordion},
  setup() {
		const json = JSON.parse(document.documentElement.dataset.viewJson || '') as ViewJsonIF
		const faqItems = json.popularFaqs
		const isSectionShow = faqItems.length != 0
		return {
			faqItems,
      isSectionShow,
		}
	}
})
