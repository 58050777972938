












import {defineComponent,reactive} from '@vue/composition-api'

export default defineComponent({
	name: "FaqAccordion",
	setup() {
		const state = reactive<{ isOpened: boolean }>({
				isOpened: false
		});
		const accordionToggle = (): void => {
				state.isOpened = !state.isOpened;
		}
		return {
				state,
				accordionToggle,
		}
	}
})
