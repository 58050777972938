










import {defineComponent} from '@vue/composition-api'
import {ThumbnailLinkItemIF} from "@/pc/ts/components/modules/ThumbnailLinkListH4.vue";
import TopFacilityContentsSection from "@/pc/ts/components/pages/top/TopFacilityContentsSection.vue";
interface FacilityFutureContentIF {
  title: string;
  text: string;
  contentType: string;
  largeImageFileName: string;
  imageAltText: string;
  linkUrl: string;
}
interface ViewJsonIF {
  gcsDomain: string;
  facilitySearchGuideContent: FacilityFutureContentIF[];
}
export default defineComponent({
  name: "TopFacilityFutureArea",
  components: {TopFacilityContentsSection},
  setup() {
    const json = JSON.parse(document.documentElement.dataset.viewJson || '') as ViewJsonIF
    const gcsDomain = json.gcsDomain
    const contentsItems: ThumbnailLinkItemIF[] = json.facilitySearchGuideContent
      .filter(c => c.contentType == 'Theme')
      .map(c => ({
        name: c.title,
        text: c.text,
        imgSrc: gcsDomain + c.largeImageFileName,
        alt: c.imageAltText,
        link: c.linkUrl,
        } as ThumbnailLinkItemIF))
    return {
      contentsItems,
    }
  }
})
